import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Row, Table } from 'react-bootstrap';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesCharinb: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters info</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_char.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Characters info</h1>
          <h2>
            Characters system in AFK Journey explained - rarities, classes,
            factions and more.
          </h2>
          <p>
            Last updated: <strong>22/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Characters" />
        <StaticImage
          src="../../../images/afk/generic/beginner_3.webp"
          alt="Guides"
        />
        <p>
          If you've played AFK Arena, you will see a lot of familiar faces in
          AFK Journey, but the character system has quite a lot of differences
          between the games.
        </p>
        <SectionHeader title="Summary" />
        <ul className="more-spacing">
          <li>
            Characters belong to one of 6 factions: <AFKItem name="Maulers" />{' '}
            <AFKItem name="Lightbearers" /> <AFKItem name="Wilders" />{' '}
            <AFKItem name="Graveborn" /> <AFKItem name="Celestial" />{' '}
            <AFKItem name="Hypogean" />,
          </li>
          <ul>
            <li>
              <AFKItem name="Celestial" /> <AFKItem name="Hypogean" /> are the
              'Angel / Devil' faction of the game and they are harder to obtain
              and ascend,
            </li>
          </ul>
          <li>
            Characters belong to one of 6 classes:{' '}
            <StaticImage
              src="../../../images/afk/icons/class_mage.png"
              alt="Mage"
              className="faction-icon"
            />{' '}
            <strong>Mage</strong>{' '}
            <StaticImage
              src="../../../images/afk/icons/class_mark.png"
              alt="Marksman"
              className="faction-icon"
            />{' '}
            <strong>Marksman</strong>{' '}
            <StaticImage
              src="../../../images/afk/icons/class_rogue.png"
              alt="Rogue"
              className="faction-icon"
            />{' '}
            <strong>Rogue</strong>{' '}
            <StaticImage
              src="../../../images/afk/icons/class_support.png"
              alt="Support"
              className="faction-icon"
            />{' '}
            <strong>Support</strong>{' '}
            <StaticImage
              src="../../../images/afk/icons/class_tank.png"
              alt="Tank"
              className="faction-icon"
            />{' '}
            <strong>Tank</strong>{' '}
            <StaticImage
              src="../../../images/afk/icons/class_warrior.png"
              alt="Warrior"
              className="faction-icon"
            />{' '}
            <strong>Warrior</strong>,
          </li>
          <li>
            Characters in this game are split into <AFKItem name="A-Level" />{' '}
            and two <AFKItem name="S-Level" />,
          </li>
          <ul>
            <li>
              <AFKItem name="A-Level" /> characters are easier to obtain from
              gacha and also cost less in various shops compared to{' '}
              <AFKItem name="S-Level" /> ones,
            </li>
            <li>
              But this doesn't make them weaker - among the{' '}
              <AFKItem name="A-Level" /> characters you can actually find some
              of the best characters in the game,
            </li>
            <li>
              Both <AFKItem name="A-Level" /> and <AFKItem name="S-Level" /> can
              be <strong>ascended to the highest tier</strong> (Supreme +),
            </li>
          </ul>
          <li>
            <strong>The fodder system has been removed</strong> and instead, you
            just need dupes of the character you want to ascend or Acorns
            (faction-tied currency that you get from shops and pulls),
          </li>
          <li>
            Each character has 3 skills which will automatically level up
            whenever they reach the required level,
          </li>
          <li>
            A character needs to reach the{' '}
            <strong className="legendary">Legendary+</strong> tier to unlock{' '}
            <strong>Hero Focus</strong> that gives them bonus to one of their
            stats or gives a brand new effect,
          </li>
          <li>
            A character needs to reach the{' '}
            <strong className="mythic">Mythic+</strong> tier to unlock{' '}
            <strong>Exclusive Weapon</strong> which gives them a new passive
            skill (that can be upgraded up by leveling the EX Weapon),
          </li>
          <li>
            A character needs to reach the{' '}
            <strong className="supreme">Supreme+</strong> tier to unlock{' '}
            <strong>Skill Enhancement</strong> that give an additional effect to
            one of the their skills,
          </li>
          <li>
            <strong>
              Characters don't need to be equipped with gear anymore
            </strong>
            , as the system has been replaced by Class-based Equipment system -
            you simply gather gear that the Class can wear and every character
            within the Class will automatically wear it in the battle,
          </li>
          <li>
            Thanks to the <strong>Resonating Hall</strong>, you just need to
            level 5 characters and automatically every other character on your
            account will be leveled up to the lowest level among those 5 - no
            need to reset characters or switch them around with limited spots
            like in AFK Arena,
          </li>
          <li>
            Also at any point, you can{' '}
            <strong>change the top 5 characters in the Resonating Hall</strong>{' '}
            by simply dragging them around.
          </li>
        </ul>
        <SectionHeader title="Factions" />
        <p>
          Here's some more information about each of the 8 available factions.
        </p>
        <div className="afk-factions-table">
          <div className="faction-div Lightbearer">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_light.png"
                alt="Lightbearers"
                className="faction-icon"
              />{' '}
              Lightbearers
            </h5>
            <div className="info">
              <p>
                <strong>The Lightbearers</strong> are heroes who serve in the
                name of Dura. They mainly fight with Hypogeans, Maulers, and
                Graveborns. Their main goal is to restore the land of Esperia
                from evil.
              </p>
              <div className="heroes">
                <p>Example heroes:</p>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="rowan"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="vala"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="lucius"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="faction-div Mauler">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_mauler.png"
                alt="Mauler"
                className="faction-icon"
              />{' '}
              Maulers
            </h5>
            <div className="info">
              <p>
                <strong>The Maulers</strong> are a warrior culture who believe
                strength is everything, with the worth of something or someone
                judged by might and usability. Though some have fought for the
                Hypogeans according to some Peaks of Time stories, this is
                relatively rare and as a whole are still fighting on the side of
                Dura.
              </p>
              <div className="heroes">
                <p>Example heroes:</p>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="brutus"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="smokey-and-meerky"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="kruger"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="satrana"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="faction-div Wilder">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_wilder.png"
                alt="Wilder"
                className="faction-icon"
              />{' '}
              Wilders
            </h5>
            <div className="info">
              <p>
                <strong>The Wilders</strong> hold a magical connection with the
                forest and with Yggdrasil, which is often exhibited in their
                skills. It is said that Dura planted Yggdrasil, and left the
                spring of life as a parting gift to the Wilders.
              </p>
              <div className="heroes">
                <p>Example heroes:</p>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="eironn"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="hewynn"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="arden"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="parisa"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="faction-div Graveborn">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_grave.png"
                alt="Graveborn"
                className="faction-icon"
              />{' '}
              Graveborn
            </h5>
            <div className="info">
              <p>
                <strong>The Graveborn</strong> are heroes who serve in death,
                revived as the Undead, and who seek immortality through dark
                arts and necromancy. It is not confirmed however if the
                Graveborn serve the Hypogeans.
              </p>
              <div className="heroes">
                <p>Example heroes:</p>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="cecia"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="thoran"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="silvina"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="niru"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="faction-div Celestial">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_cele.png"
                alt="Celestial"
                className="faction-icon"
              />{' '}
              Celestials
            </h5>
            <div className="info">
              <p>
                <strong>The Celestials</strong> are a main faction in AFK Arena,
                and also in Journey. Their main goal is to destroy the Hypogeans
                and any remaining Darkness plaguing Esperia.
              </p>
              <div className="heroes">
                <p>Example heroes:</p>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="dionel"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="scarlita"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="faction-div Hypogean">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_hypo.png"
                alt="Hypogean"
                className="faction-icon"
              />{' '}
              Hypogeans
            </h5>
            <div className="info">
              <p>
                <strong>The Hypogeans</strong> are the main villains of AFK
                Arena, and maybe they serve the same role in Journey. Their main
                goal is to destroy any of Dura's creations by spreading evil all
                across Esperia.
              </p>
              <div className="heroes">
                <p>Example heroes:</p>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="berial"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                  <AFKCharacter
                    mode="card"
                    slug="reinier"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SectionHeader title="Archetypes" />
        <p>
          The class categorizations only loosely define what kinds of abilities
          the characters will have. Tanks for example, are usually durable front
          liners designed to take a brunt of the damage, but there are a lot of
          categories also within the classes themselves.
        </p>
        <div className="afk-archetypes">
          <div className="column">
            <h5 className="dps">DPS / Damage Dealer</h5>
            <p>
              These heroes focus on outputting high amounts of single-target or
              AoE damage. Their kit is usually based around just this, but some
              DPS heroes also provide other things such as Crowd Control or
              sustain.
            </p>
            <p>Example heroes:</p>
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="valen"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="bryon"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="cecia"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="seth"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <div className="column">
            <h5 className="spec">Specialist</h5>
            <p>
              Heroes in this category are often damage dealers who have a great
              portion of their kit dedicated to gimmicks like Crowd Control,
              debuffs, buffs or other Support. They are sometimes
              interchangeable with DPS.
            </p>
            <p>Example heroes:</p>
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="eironn"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="kruger"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="lyca"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="carolina"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <div className="column">
            <h5 className="support">Support</h5>
            <p>
              The heroes in this role are centered around keeping the team alive
              and powerful through a combination of healing, buffing and other
              defensive gimmicks.
            </p>
            <p>Example heroes:</p>
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="rowan"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="smokey-and-meerky"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="hewynn"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="niru"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <div className="column">
            <h5 className="tank">Tank</h5>
            <p>
              Heroes belonging to this role are durable frontliners built to
              take a brunt of the incoming damage and enemy aggro. With a
              combination of sustain, defensive buffing and crowd control they
              ensure the safety of their fragile backline.
            </p>
            <p>Example heroes:</p>
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="thoran"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="brutus"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="lucius"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="lumont"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
        </div>
        <p>
          If you want to check all characters that are available in the game, go
          here:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Characters list"
            link="/afk-journey/characters"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_characters.jpg"
                alt="Characters list"
              />
            }
          />
        </Row>
        <SectionHeader title="Equipment" />
        <StaticImage
          src="../../../images/afk/generic/beginner_5.webp"
          alt="Guides"
        />
        <p>
          <strong>
            Characters in AFK Journey don't need to equip gear anymore
          </strong>
          , as the system has been replaced by Class-based Equipment system -
          you simply gather gear that the Class can wear and every character
          within the Class will automatically wear it in the battle. This makes
          gear management not only much easier to handle, but also it allows you
          to try different characters and easily swap them before the battle
          when you need their specific skillset.
        </p>
        <p>
          To find out the best priority of equipment upgrades for each class and
          more information about the Equipment, check our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Equipment & upgrade priority"
            link="/afk-journey/guides/equipment-and-priority"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_eq.jpg"
                alt="Characters list"
              />
            }
          />
        </Row>
        <SectionHeader title="Exclusive Equipment" />
        <StaticImage
          src="../../../images/afk/generic/beginner_9.webp"
          alt="Guides"
        />
        <p>
          <strong>
            When you upgrade a character to their Mythic+ tier, their Exclusive
            Item becomes available to unlock and upgrade
          </strong>
          . This Exclusive Item grants a set of stats tailored towards the
          character it is for and{' '}
          <strong>unlocks a 4th ability in their kit</strong> (known as an EX or
          Exclusive Skill).
        </p>
        <Table striped bordered responsive className="afk-ex">
          <thead>
            <tr>
              <th>EX Levels</th>
              <th>New passive?</th>
              <th>Stone (total)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>EX+1 to EX+5</td>
              <td>
                <strong className="green">Yes</strong>
              </td>
              <td>
                <div className="combined">
                  150x <AFKItem name="Tidal Essence" />
                </div>
              </td>
            </tr>
            <tr>
              <td>EX+6 to EX+10</td>
              <td>
                <strong className="green">Yes</strong>
              </td>
              <td>
                <div className="combined">
                  75x <AFKItem name="Temporal Essence" />
                </div>
              </td>
            </tr>
            <tr>
              <td>EX+11 to EX+15</td>
              <td>
                <strong className="green">Yes</strong>
              </td>
              <td>
                <div className="combined">
                  200x <AFKItem name="Temporal Essence" />
                </div>
              </td>
            </tr>
            <tr>
              <td>EX+16 to EX+20</td>
              <td>
                <strong className="red">No</strong>
              </td>
              <td>
                <div className="combined">
                  125x <AFKItem name="Twilight Essence" />
                </div>
              </td>
            </tr>
            <tr>
              <td>EX+21 to EX+25</td>
              <td>
                <strong className="red">No</strong>
              </td>
              <td>
                <div className="combined">
                  500x <AFKItem name="Twilight Essence" />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Leveling up" />
        <h5>Before 240</h5>
        <ul>
          <li>
            To level up a character you will need <AFKItem name="Gold" /> and{' '}
            <AFKItem name="Experience" /> - both can be obtained from AFK Stages
            and as various rewards,
          </li>
          <li>
            Still, every 10 levels, on top of <AFKItem name="Gold" /> and{' '}
            <AFKItem name="Experience" /> you will also need{' '}
            <AFKItem name="Essence" />. And Essence will usually be the material
            you will lack the most and you will have to 'idle' for hours (and
            later days) to obtain enough of it to push a character through the
            level 10 upgrade.
          </li>
        </ul>
        <h5>Post 240</h5>
        <p>
          This system changes once you reach the <strong>240 level cap</strong>{' '}
          on your top 5 characters and now instead of leveling them, you will
          level the Resonance Hall. And the level cap will increase by 5 for
          every <strong className="supreme">Supreme+</strong> character you
          posses. You will also need only <AFKItem name="Essence" /> moving
          forward and on top of that you will obtain <AFKItem name="Diamonds" />{' '}
          every time you level past level 300.
        </p>
        <SectionHeader title="Ascension" />
        <p>
          In AFK Journey the concept of fodder has been removed and the whole{' '}
          <strong>Ascension system has been simplified</strong>. Now, to ascend
          a character you either need to gather their dupes or faction-tied
          Acorns (which can be obtained from various shops or from pulling). The
          table below shows all the currently available tiers in the game and
          the requirements to reach them.
        </p>
        <p>To learn more about the Ascension system, check this guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Ascension info"
            link="/afk-journey/guides/ascension-info"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_ascension.jpg"
                alt="Ascension info"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesCharinb;

export const Head: React.FC = () => (
  <Seo
    title="Characters info | AFK Journey | Prydwen Institute"
    description="Characters system in AFK Journey explained - rarities, classes, factions and more."
    game="afk"
  />
);
